import React from "react";
import TweenOne from "rc-tween-one";
import { Menu, Affix, Col, Row, Divider } from "antd";
import { getChildrenToRender } from "./utils";

const { Item, SubMenu } = Menu;

class Header3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header3-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header3-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <a {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </a>
              ) : (
                <div {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <a {...a} className={`header3-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </a>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <Affix offsetTop={0}>
        <TweenOne
          component="header"
          animation={{ opacity: 0, type: "from" }}
          {...dataSource.wrapper}
          {...props}
        >
          <div
            {...dataSource.page}
            className={`${dataSource.page.className}${
              phoneOpen ? " open" : ""
            }`}
          >
            <TweenOne
              animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
              {...dataSource.logo}
            >
              <Row style={{ width: "225px" }}>
                <Col span={8}><img width="50" src={dataSource.logo.children} alt="img" /></Col>
                <Col span={16}><span>火喵<Divider type="vertical" />又是摸鱼的一天</span></Col>
              </Row>
            </TweenOne>
            {isMobile && (
              <div
                {...dataSource.mobileMenu}
                onClick={() => {
                  this.phoneClick();
                }}
              >
                <em />
                <em />
                <em />
              </div>
            )}
            {/* <TweenOne
              {...dataSource.Menu}
              animation={
                isMobile
                  ? {
                      x: 0,
                      height: 0,
                      duration: 300,
                      onComplete: (e) => {
                        if (this.state.phoneOpen) {
                          e.target.style.height = "auto";
                        }
                      },
                      ease: "easeInOutQuad",
                    }
                  : null
              }
              moment={moment}
              reverse={!!phoneOpen}
            >
              <Menu
                mode={isMobile ? "inline" : "horizontal"}
                defaultSelectedKeys={["sub0"]}
                theme="light"
                onClick={(e) => console.log(e)}
              >
                {navChildren}
              </Menu>
            </TweenOne> */}
          </div>
        </TweenOne>
      </Affix>
    );
  }
}

export default Header3;
