import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import MarkNav from "markdown-navbar";
import mdInfo from "./info.md";
import { Layout, Affix } from "antd";
import { enquireScreen } from "enquire-js";
import "markdown-navbar/dist/navbar.css";
import "github-markdown-css";
import "./index.css";
const { Content, Sider } = Layout;

const App = () => {
  const [mdSource, setMd] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetch(mdInfo)
      .then((res) => res.text())
      .then((text) => setMd(text));
    enquireScreen((b) => {
      setIsMobile(b);
    });
  }, []);

  return (
    <Layout style={{ padding: !isMobile ? "24px 0 24px 30px" : "10px 0 10px 0" }}>
      {!isMobile && (
        <Affix offsetTop={88}>
          <Sider
            style={{
              // height: '80vh',
              // height: '700px',
              minHeight: 400,
              background: "#ffffff",
              padding: "0 0 24px",
            }}
            width={300}
          >
            <MarkNav
              className="MarkNav"
              source={mdSource}
              headingTopOffset={100}
              // updateHashAuto={false}
              ordered={false}
            />
          </Sider>
        </Affix>
      )}
      <Content style={{ padding: "0 24px", minHeight: 280 }}>
        <div
          className="markdown-body content"
          style={{ padding: "24px 40px 24px 40px" }}
        >
          <ReactMarkdown children={mdSource} remarkPlugins={[remarkGfm]} />
        </div>
      </Content>
    </Layout>
  );
};

export default App;
