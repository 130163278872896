import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './pages/Home/Nav3';
import Footer from './pages/Home/Footer2';
import Home from './pages/Home';
import Makedown from './pages/Makedown';

import {
  Nav30DataSource,
  Footer20DataSource,
} from './pages/Home/data.source.js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

function route() {
  const BaseLayout = (content) => {
    return <>
      <Header dataSource={Nav30DataSource} isMobile={isMobile} />
        {content}
      <Footer dataSource={Footer20DataSource} isMobile={isMobile} />
    </>
  }

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={BaseLayout(<Home />)}></Route>
          {/* <Route path="/md" element={BaseLayout(<Makedown />)}></Route> */}
        </Routes>
      </Router>
    </div>
  );
}
export default route;